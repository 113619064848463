import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import MainNav from "../components/MainNav"
import Footer from "../components/Footer"
import FullWidthHero from "../components/FullWidthHero"
import StaticArticleContainer from "../components/StaticArticleContainer"
import { theme } from "../styles/global-styles"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const JavaScript = () => {
  return (
    <Layout>
      <SEO title="JavaScript the Basics. Cheat Sheets, Links, and Ramblings" />
      <MainNav
        logoAndMenuColor={theme.colors.light}
        bgColor={theme.colors.dark}
      />
      <FullWidthHero
        backgroundColor={theme.colors.dark}
        headerColor={theme.colors.light}
        headerText={"The JavaScript Basics Guide for Frustrated Noob's"}
      />
      <StaticArticleContainer />
      <Footer />
    </Layout>
  )
}

export default JavaScript
