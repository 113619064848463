import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import SmallSectionHeader from "./SmallSectionHeader"
import { theme } from "../styles/global-styles"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import SyntaxHighlighter from "react-syntax-highlighter"
import { foundation } from "react-syntax-highlighter/dist/esm/styles/hljs"

import mySVG from "../images/JMBackground-01.svg"
import mySVGAccentOne from "../images/JMBackground-AccentOne.svg"

const StaticArticleWrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
`

const StaticArticle = styled.article`
  width: 100%;
  margin: 4em;
  max-width: ${theme.widths.mainContainer};
  @media only screen and (max-width: 959px) {
    max-width: ${theme.widths.smContainer};
  }
  @media only screen and (max-width: 599px) {
    margin: 1.5em;
  }
`
const StaticArticleText = styled.div`
  /* width: 100%; */
  padding: 2rem 0;
`

const StaticArticleDate = styled.h6``

const SectionBreak = styled.section`
  background-color: ${props => props.bgColor};
  width: 100%;
`

const BackgroundImage = styled.div`
  background-image: url(${props => props.bgImage});
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StaticArticleContainer = props => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "JMBackground.png" }) {
        id
        childImageSharp {
          fixed {
            src
            srcSet
          }
          fluid {
            src
            srcSet
          }
        }
      }
    }
  `)

  // console.log(
  //   "I am data: ",
  //   data.image.childImageSharp.fixed.srcSet.split(",")[2].slice(0, -3)
  // )

  console.log("I am data: ", mySVG)

  return (
    <>
      <StaticArticleWrapper>
        <StaticArticle>
          {/* <Img fluid={data.article.childImageSharp.fluid} /> */}
          {/* <SmallSectionHeader
          color={theme.colors.accentTwo}
          pointsForSVG={false}
          headerTitle={props.publishDate}
          margBottom={"2rem"}
        /> */}

          <StaticArticleText>
            <h2
              css={css`
                margin-top: 0;
              `}
            >
              This Isn't For You
            </h2>
            <p>
              The trouble with learning learning to code is that a lot of
              learning material is written by people needing to show technical
              prowess. They're producing content to earn credibility with peers
              and employers so they heap technical terms and jargon on to...the
              heap.
            </p>
            <p>
              You dive into some tutorial trying to grasp what ought to be
              relatively simple concepts but then hit a wall of obfuscation,
              abstract language, and more foos, bars, buzzes, and bazzes than
              anyone should have to encounter.
            </p>
            <p>
              Whilst this guide tries to solve for that its not an excuse to get
              lazy with the technical terms—they're important to learn. You
              ought to understand the jargon. It provides shared language,
              better communication, and increased mental access to documention.
            </p>
            <p>Here's what's on the menu:</p>
            <ul>
              <li>Variables</li>
              <li>Objects</li>
              <li>Arrays</li>
              <li>Functions</li>
            </ul>
            <p>
              This isn't an excuse to get lazy with the technical terms, they're
              important to learn. You ought to understand the jargon. It
              provides shared language, better communication, and increased
              mental access to documention.
            </p>
            <p>
              {" "}
              By using non-technical terms, the explanations in this guide will
              be incomplete and important concepts will be lost.
            </p>

            <SyntaxHighlighter
              wrapLines={true}
              useInlineStyles={false}
              language={"jsx"}
            >
              {`
const someVariableName = 'some line of text'

let myVariable = 5

const someObj = {
    myMethod: function(props) {
      return props.valueOne + props.valueTwo;
    }
  }

let something = 'this thing and that thing'
[2,3,5].forEach( (props) => { return 'something' } )
              `}
            </SyntaxHighlighter>
          </StaticArticleText>
        </StaticArticle>
      </StaticArticleWrapper>

      <SectionBreak bgColor={theme.colors.main}>
        <BackgroundImage bgImage={mySVG}>
          <StaticArticle>
            <h3
              css={css`
                color: white;
                margin-top: 0;
              `}
            >
              Some Sort of Header Element Here. Something that We can Write
              here.
            </h3>
            <p
              css={css`
                color: white;
                margin-bottom: 0;
              `}
            >
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Similique, recusandae. Exercitationem, eos, dignissimos expedita
              accusamus omnis laudantium quisquam in mollitia odio doloremque
              enim ea fugiat iste reiciendis nesciunt, aliquam pariatur?
            </p>
          </StaticArticle>
        </BackgroundImage>
      </SectionBreak>

      <StaticArticleWrapper>
        <StaticArticle>
          {/* <Img fluid={data.article.childImageSharp.fluid} /> */}
          {/* <SmallSectionHeader
          color={theme.colors.accentTwo}
          pointsForSVG={false}
          headerTitle={props.publishDate}
          margBottom={"2rem"}
        /> */}

          <StaticArticleText>
            <div>
              <h2
                css={css`
                  margin-top: 0;
                `}
              >
                This Isn't For You
              </h2>
              <p>
                The trouble with learning Javascript—learning to code in
                general—is that a lot of learning material was written by people
                needing to show technical prowess in hopes of getting a job or a
                raise. It's written for employers not noobs so technical terms
                get packed in, obfuscating simple concepts behind jargon.
              </p>
              <p>
                It's frustrating when abstract concepts are taught using
                abstract language.
              </p>
              <p>This guide tries to solve for that.</p>
              <p>
                However, this creates a trade off. Easier initial learning in
                exchange for a less stable foundation.
              </p>
              <p>
                With that in mind, approach this guide only if you feel like
                quitting or find yourself deeply frustrated with other learnings
                on the proverbial menu. If you're not there yet, move on. This
                guide isn't for you.
              </p>
              <p>
                You need to learn the technical terms. They're important. You
                ought to speak the jargon. It allows you to properly
                communicate. By using non-technical terms, the explanations in
                this guide will be incomplete and important concepts will be
                lost.
              </p>
            </div>

            <SyntaxHighlighter
              wrapLines={true}
              useInlineStyles={false}
              language={"jsx"}
            >
              {`
const someVariableName = 'some line of text'

let myVariable = 5

const someObj = {
    myMethod: function(props) {
      return props.valueOne + props.valueTwo;
    }
  }

let something = 'this thing and that thing'
[2,3,5].forEach( (props) => { return 'something' } )
              `}
            </SyntaxHighlighter>
          </StaticArticleText>
        </StaticArticle>
      </StaticArticleWrapper>
      <SectionBreak bgColor={theme.colors.dark}>
        <BackgroundImage bgImage={mySVGAccentOne}>
          <StaticArticle>
            <h3
              css={css`
                color: white;
                margin-top: 0;
              `}
            >
              Some Sort of Header Element Here. Something that We can Write
              here.
            </h3>
            <p
              css={css`
                color: white;
                margin-bottom: 0;
              `}
            >
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Similique, recusandae. Exercitationem, eos, dignissimos expedita
              accusamus omnis laudantium quisquam in mollitia odio doloremque
              enim ea fugiat iste reiciendis nesciunt, aliquam pariatur?
            </p>
          </StaticArticle>
        </BackgroundImage>
      </SectionBreak>
    </>
  )
}

export default StaticArticleContainer
